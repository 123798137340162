<template>
  <v-form v-show="dispLogin">
    <v-container>
      <v-row align="center" justify="space-around" class="mt-2">
        <p class="comment-text-width text-center mb-0">
          新規登録・ログインどちらも下にあるボタンから行えます。
        </p>
      </v-row>
      <v-row align="center" justify="space-around" class="mt-0">
        <div id="firebaseui-auth-container"></div>
      </v-row>
      <v-row align="center" justify="space-around" class="mt-2">
        <p class="comment-text-width text-center">
          名前、プロフィール画像を使用します。名前はプロフィール画面から自由に変更できます。
        </p>
      </v-row>
    </v-container>
  </v-form>
</template>
<style>
  .comment-text-width {
    width: 95vw;
  }
</style>
<script>
  import { mapActions } from 'vuex'
  import firebase from '../lib/firebase'
  import db from '../lib/db'
  import define from '../Define'
  import firebaseui from 'firebaseui-ja'
  import 'firebaseui-ja/dist/firebaseui.css'
  export default {
    name: 'Login',
    data: () => {
      return {
        alertLoginSuccess: false,
        alertLoginFail: false,
        alertDisp: false,
        alertText: '',
        alertColor: '',
        dispLogin: true,
      }
    },
    methods: {
      ...mapActions('user', ['signIn', 'signOut', 'checkAuth', 'setDisplayName', 'setLearn', 'setAlert']),
      ...mapActions('learn', ['setCond']),
      ...mapActions('views', ['setSnack']),
      /**
       * Googleでログイン
       */
      async login() {
        //store/actionで処理する
        // this.signIn()
        await firebase
          .auth()
          .signInWithPopup(new firebase.auth.GoogleAuthProvider())
          .then((res) => {
            const user = res.user
            this.signIn({ user })
            this.isUserData(user)
            this.setAlert(user.uid)
            this.$router.push('/')
          })
          .catch((error) => {
            this.setSnack(define.MESSAGE_LOGIN_FAILED)
          })
      },
      setSignIn(user) {
        this.signIn({ user })
      },
      async isUserData(user) {
        const dbData = await db
          .collection('user')
          .where('uid', '==', user.uid)
          .limit(1)
          .get()
          .then((snapShot) => {
            if (snapShot.empty) {
              const nowDatetime = firebase.firestore.FieldValue.serverTimestamp()

              db.collection('user')
                .doc(user.uid)
                .set({
                  uid: user.uid,
                  name: user.displayName,
                  learning: '',
                  getted: [],
                  created_at: nowDatetime,
                  updated_at: nowDatetime,
                  image: user.photoURL,
                })
              this.setSnack(define.MESSAGE_LOGIN_SUCCESS_FIRST)
            } else {
              snapShot.forEach((doc) => {
                const data = doc.data()
                this.setDisplayName(data.name)
                this.setLearn(data.learning)
                //勝手に検索条件が変わるのも違和感なので一時的にコメントアウト
                // this.setCond(data.learning)
                this.setSnack(define.MESSAGE_LOGIN_SUCCESS)
              })
            }
          })
          .catch(() => {
            this.setSnack(define.MESSAGE_LOGIN_FAILED)
          })
      },
    },
    created() {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          this.dispLogin = false
          this.signIn({ user })
          await this.isUserData(user)
          await this.$root.$refs.App.getAlert()
          this.$router.push('/').catch((err) => {})
        }
      })
    },
    mounted() {
      const uiConfig = {
        // 認証時に同一ウィンドウで表示
        signInFlow: 'popup',
        // 各認証
        signInOptions: [
          // メール認証
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        // 利用規約へリンク
        tosUrl: '/terms',
        // プライバシーポリシーリンク
        privacyPolicyUrl: '/privacy',
      }

      // 認証UI表示
      let ui = firebaseui.auth.AuthUI.getInstance()
      if (!ui) {
        ui = new firebaseui.auth.AuthUI(firebase.auth())
      }
      ui.start('#firebaseui-auth-container', uiConfig)
    },
  }
</script>
